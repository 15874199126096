(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home', {
        url: '/home',
        templateUrl: 'home/home.tpl.html',
        controller: 'HomeCtrl',
        controllerAs: 'vm'
      })
      .state('search', {
        url: '/search',
        templateUrl: 'home/search.tpl.html',
        controller: 'SearchCtrl',
        controllerAs: 'vm'
      });
  }
}());
